<template>
  <div id="order">
    <div
      :class="{ imageHeightWithReview: $screen.width <= 800 && order.review != null, imageHeightWithoutReview: $screen.width <= 800 && order.review == null  }"
      id="image-container"
    >
      <img
        @click="goToReviews"
        id="store-image"
        :class="{ 'cursor-pointer': order.store.ratingEnabled }"
        :src="`${order.store.logoLink}`"
        alt
      />
    </div>
    <div id="order-details">
      <div id="store-n-date">
        <h4
          @click="goToReviews"
          id="store-label"
          :class="{ 'cursor-pointer': order.store.ratingEnabled }"
        >{{ order.store.names.translation[language].name }}</h4>
        <div>
          <h4 class="date">{{ order.date.slice(0, -3) }}</h4>
          <div v-if="order.scheduled && order.deliveryDate != null && order.deliveryDate != ''">
            <h4 class="scheduled-order-header">{{labels.myOrders.scheduledOrderForDate[language]}}</h4>
            <h4 class="delivery-date">{{ convertDeliveryDateToStringForUser(order) }}</h4>
          </div>
          <h4 id="membership-info" v-if="order.membership">
            {{ order.store.hotel ? labels.myOrders.allInclusiveOrder[language] : labels.myOrders.membershipOrder[language] }}
            <font-awesome-icon style="margin-left:6px" :icon="['fas', 'user']" />
          </h4>
        </div>
      </div>
      <div class="cart-items-container">
        <div
          v-for="(item, index) in order.products"
          :key="index"
          class="cart-item"
        >{{item.quantity+' x '+item.product.names.translation[language].name}}</div>
      </div>
      <div id="cost-container">
        <h4 id="cost">{{ labels.myOrders.price[language] }}: {{ order.price }}€</h4>
        <div v-if="wasInHappyHour" id="happy-hour">
          <h4>Happy Hour</h4>
        </div>
      </div>

      <!-- <div v-if="$screen.width <= 800" id="leave-review-container">
        <div id="leave-review-button-n-review">
          <Button
            @click="leaveReview"
            id="leave-review-button"
            :disabled="order.review != null || !order.store.ratingEnabled"
          >{{ buttonText }}</Button>
          <div v-if="order.review != null" id="stars">
            <font-awesome-icon
              id="star-1"
              :class="{ starIcon: true, yellow: order.review.rating > 0 }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              id="star-2"
              :class="{ starIcon: true, yellow: order.review.rating > 1 }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              id="star-3"
              :class="{ starIcon: true, yellow: order.review.rating > 2 }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              id="star-4"
              :class="{ starIcon: true, yellow: order.review.rating > 3 }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              id="star-5"
              :class="{ starIcon: true, yellow: order.review.rating > 4 }"
              icon="star"
            ></font-awesome-icon>
          </div>
        </div>
      </div>-->
    </div>

    <!-- <div v-if="$screen.width > 800" id="leave-review-container">
      <div id="leave-review-button-n-review">
        <Button
          @click="leaveReview"
          id="leave-review-button"
          :disabled="order.review != null || !order.store.ratingEnabled"
        >{{ buttonText }}</Button>
        <div v-if="order.review != null" id="stars">
          <font-awesome-icon
            id="star-1"
            :class="{ starIcon: true, yellow: order.review.rating > 0 }"
            icon="star"
          ></font-awesome-icon>
          <font-awesome-icon
            id="star-2"
            :class="{ starIcon: true, yellow: order.review.rating > 1 }"
            icon="star"
          ></font-awesome-icon>
          <font-awesome-icon
            id="star-3"
            :class="{ starIcon: true, yellow: order.review.rating > 2 }"
            icon="star"
          ></font-awesome-icon>
          <font-awesome-icon
            id="star-4"
            :class="{ starIcon: true, yellow: order.review.rating > 3 }"
            icon="star"
          ></font-awesome-icon>
          <font-awesome-icon
            id="star-5"
            :class="{ starIcon: true, yellow: order.review.rating > 4 }"
            icon="star"
          ></font-awesome-icon>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
import VClamp from "vue-clamp";
import Button from "./Button";
export default {
  name: "OldOrder",
  components: {
    VClamp,
    Button,
  },
  mounted() {
  },
  computed: {

    buttonText() {
      if (this.order.review != null) {
        return this.labels.myOrders.thankYouForReview[this.language]
      } else if (!this.order.store.ratingEnabled) {
        return this.labels.myOrders.reviewsAreDisabled[this.language]
      } else {
        return this.labels.myOrders.leaveReview[this.language]
      }
    },

    wasInHappyHour() {
      return this.order.happyHour !== null;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {};
  },
  props: ["order"],
  methods: {
    leaveReview: function () {
      this.$emit("onLeaveReview");
      this.$router.push({
        name: "reviews",
        params: { orderId: this.order.id },
      });
    },

    goToReviews: function () {
      if (this.order.store.ratingEnabled) {
        this.$router.push({ name: "store-reviews", params: { storeId: this.order.restaurant.id } })
      }
    },

    convertDeliveryDateToStringForUser(order) {
      let deliveryDate = order.deliveryDate.slice(0, -3);
      if (order.scheduledTimeRanged) {
        deliveryDate = this.addTimeSelectionEndingRange(order);
      }
      return deliveryDate;
    },

    addTimeSelectionEndingRange(order) {
      let deliveryDate = order.deliveryDate.slice(0, -3);
      let deliveryDateSplitInArray = deliveryDate.split(" ");
      let selectedTime = deliveryDateSplitInArray[deliveryDateSplitInArray.length - 1];

      //if selectedTime includes "-" then it is already a range and we return the initial deliveryDate
      if (!selectedTime.includes("-")) {
        let selectedTimeSplitInArray = selectedTime.split(":");
        let hour = parseInt(selectedTimeSplitInArray[0]);
        let minutes = parseInt(selectedTimeSplitInArray[1]);
        let endRangeHour = hour;
        let endRangeMinutes = minutes + order.scheduledTimeRange;
        if (endRangeMinutes >= 60) {
          let amountOfExtraHours = Math.floor(endRangeMinutes / 60);
          endRangeMinutes = endRangeMinutes - 60 * amountOfExtraHours;
          let endRangeHourWithExtraHours = endRangeHour + amountOfExtraHours;
          endRangeHour = (endRangeHourWithExtraHours == 24) ? 0 : (endRangeHourWithExtraHours > 24) ? (endRangeHourWithExtraHours - 24) : (endRangeHourWithExtraHours);
        }

        let endRangeHourString = endRangeHour < 10 ? `0${endRangeHour}` : `${endRangeHour}`;
        let endRangeMinutesString = endRangeMinutes < 10 ? `0${endRangeMinutes}` : `${endRangeMinutes}`;
        let transformeddeliveryDate = `${deliveryDate}-${endRangeHourString}:${endRangeMinutesString}`;
        return transformeddeliveryDate;
      } else {
        return deliveryDate;
      }
    },
  },
};
</script>

<style scoped>
#order {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 2% 0 2% 0;
  z-index: 1;
}

#image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}

#store-image {
  width: 72px;
  height: 72px;
  box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.24), 2px 2px 4px rgb(0, 0, 0, 0.36);
  object-fit: cover;
  border-radius: 100%;
}

#order-details {
  width: 50%;
  height: 100%;
}

#store-n-date {
  display: flex;
  flex-direction: column;
}

#store-label {
  color: #003340;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 11px;
}

.date {
  color: #003340;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
}

.delivery-date {
  color: #1c7944;
  font-size: 13px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 5px;
}

.scheduled-order-header {
  color: #1c7944;
  font-size: 14px;
  font-weight: 800;
  white-space: pre-wrap;
}

#membership-info {
  color: #003340;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
}

.cart-items-container {
  display: flex;
  flex-direction: column;
}

.cart-item {
  color: rgb(90, 90, 90);
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 2%;
}

#cost-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

#cost {
  color: #003340;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-top: 2px;
  margin-right: 15px;
}

#happy-hour {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  border: 2px solid #fa8072;
  background: #fa8072;
}

#happy-hour > * {
  font-size: 11px;
  font-weight: 600;
  margin: 0;
  color: white;
}

#leave-review-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}

#leave-review-button-n-review {
  display: flex;
  flex-direction: column;
  width: 50%;
}

#leave-review-button {
  font-size: 14px;
  font-weight: 600;
  min-width: 100%;
  min-height: 50px;
  background: linear-gradient(62deg, #198b4a, #04da6f);
  border: none;
  color: white;
}

#leave-review-button:disabled {
  opacity: 0.75;
}

#stars {
  /* background: blue; */
  width: 100%;
  /* height: 25%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 1.5%; */
}

#ratings-disabled {
  width: 200%;
  align-self: center;
  justify-self: center;
}

#ratings-disabled > h4 {
  margin: 0;
  color: gray;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 15px;
}

.starIcon {
  width: 20px;
  height: 20px;
  margin-top: 20px;
  color: #cecece;
}

.yellow {
  color: #ffc200;
}

.increaseWidth {
  width: 80% !important;
  padding-left: 5%;
}

.add-space {
  margin-right: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  #order {
    margin: 5% 0 5% 0;
  }

  #order-details {
    width: 70%;
    margin-left: 5%;
  }

  #image-container {
    display: flex;
    /* align-items: center; */
    align-self: flex-start;
    align-items: center;
    width: 20%;
    /* background: blue; */
  }

  .imageHeightWithReview {
    height: calc(100% - 65px) !important;
  }

  .imageHeightWithoutReview {
    height: calc(100% - 30px) !important;
  }

  #store-image {
    width: 60px;
    height: 60px;
    /* transform: translateY(-65%); */
  }

  #store-label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .date {
    font-size: 11px;
    margin-bottom: 10px;
  }

  #membership-info {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .cart-item {
    font-size: 12px;
    margin-bottom: 3px;
  }

  #cost {
    font-size: 12px;
    /* margin-bottom: 5%; */
  }

  #leave-review-container {
    align-items: flex-start;
    justify-content: flex-start;
    width: 145%;
  }

  #leave-review-button-n-review {
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateX(-25%);
    margin-top: 15px;
  }

  #leave-review-button {
    font-size: 12px;
    font-weight: 600;
    min-width: 90%;
    min-height: 35px;
  }

  #stars {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20%;
  }

  #ratings-disabled {
    width: unset;
  }

  #ratings-disabled > h4 {
    font-size: 11px;
    margin-top: 10px;
  }

  .starIcon {
    width: 15px;
    height: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #cecece;
  }

  .yellow {
    color: #ffc200;
  }

  .increaseWidth {
    width: 80% !important;
    padding-left: 5%;
  }

  .add-space {
    margin-right: 5px;
  }
}
@media screen and (max-width: 276px) {
  .delivery-date {
    font-size: 12px;
  }

  .scheduled-order-header {
    font-size: 12px;
  }
}
</style>
